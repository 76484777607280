import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CrosswordPage from './CrosswordPage';
import LandingPage from './LandingPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/saint" element={<CrosswordPage />} />
                <Route path="/" element={<LandingPage/>} />
            </Routes>
        </Router>
    );
}

export default App;