import './App.css';

import Crossword from '@jaredreisinger/react-crossword';


const data = {
  across: {
    1: {
      clue: 'You helped me learn but also won\'t let me do',
      answer: 'SWIM',
      row: 0,
      col: 4,
    },
    3: {
      clue: 'Location of magic Christmas garden adventure',
      answer: 'KEW',
      row: 2,
      col: 3,
    },
    5: {
      clue: 'Your new favourite vegan friend',
      answer: 'ROSELYN',
      row: 3,
      col: 6,
    },
    6: {
      clue: 'Where you first messaged me',
      answer: 'BICESTER',
      row: 6,
      col: 7,
    },
    8: {
      clue: 'My first album for you',
      answer: 'TIMELY',
      row: 7,
      col: 16,
    },
    9: {
      clue: 'First wedding together venue',
      answer: 'CORINTHIA',
      row: 8,
      col: 5,
    },
    13: {
      clue: 'Avatar\'s Love',
      answer: 'KALIMBA',
      row: 9,
      col: 14,
    },
    15: {
      clue: 'What we we\'re buying when you said you loved me',
      answer: 'TREE',
      row: 11,
      col: 0,
    },
    18: {
      clue: 'Where we looked at stars',
      answer: 'OBSERVATORY',
      row: 12,
      col: 7,
    },
    20: {
      clue: 'O2 artist',
      answer: 'YIRUMA',
      row: 12,
      col: 19,
    },
    24: {
      clue: 'The show we owe everything to',
      answer: 'AVATAR',
      row: 15,
      col: 0,
    },
    27: {
      clue: 'Waterloo friend',
      answer: 'MOUSE',
      row: 15,
      col: 16,
    },
    29: {
      clue: 'Last word of the title of the first book I read for you',
      answer: 'GORGEOUS',
      row: 17,
      col: 7,
    },
    30: {
      clue: 'The brand name of the thing I never leave the house without wearing',
      answer: 'CASIO',
      row: 18,
      col: 1,
    },
    31: {
      clue: 'First sweet treat candy',
      answer: 'HICHEW',
      row: 19,
      col: 13,
    },
    32: {
      clue: 'Location of: "Closed when work in progress? How do I know if works is in progress?!"',
      answer: 'MAZEHILL',
      row: 20,
      col: 1,
    },
    34: {
      clue: 'Seven days a week?',
      answer: 'JUNGKOOK',
      row: 21,
      col: 11,
    },
    36: {
      clue: 'Switch sport I can never beat you in',
      answer: 'GOLF',
      row: 22,
      col: 3,
    },
    37: {
      clue: 'Our shared music genre',
      answer: 'CITYPOP',
      row: 23,
      col: 7,
    },
    38: {
      clue: '___ my kebob!',
      answer: 'SHISH',
      row: 24,
      col: 15,
    },
  },
  down: {
    1: {
      clue: 'In your favourite sandwich',
      answer: 'STEAK',
      row: 0,
      col: 4,
    },
    2: {
      clue: 'Dinosaur drink ingredient',
      answer: 'MILO',
      row: 0,
      col: 7,
    },
    4: {
      clue: 'First date cuisine',
      answer: 'MEXICAN',
      row: 2,
      col: 9,
    },
    7: {
      clue: 'Location of my first temple',
      answer: 'WEMBLEY',
      row: 6,
      col: 19,
    },
    9: {
      clue: 'Ship and train confusion',
      answer: 'CUTTYSARK',
      row: 8,
      col: 5,
    },
    10: {
      clue: 'What I hate',
      answer: 'HAJMOLA',
      row: 8,
      col: 15,
    },
    11: {
      clue: 'Jellycat animal',
      answer: 'PANDA',
      row: 8,
      col: 24,
    },
    12: {
      clue: 'Magical Charing X Instrument',
      answer: 'KORA',
      row: 9,
      col: 1,
    },
    14: {
      clue: 'First of the loudest words you\'ve heard me say',
      answer: 'SECRET',
      row: 10,
      col: 3,
    },
    16: {
      clue: 'Your anniversary gift',
      answer: 'BRACELET',
      row: 11,
      col: 11,
    },
    17: {
      clue: 'Second of the loudest words you\'ve heard me say',
      answer: 'TUNNEL',
      row: 11,
      col: 22,
    },
    19: {
      clue: 'Last word of your favourite poems',
      answer: 'SEX',
      row: 12,
      col: 9,
    },
    21: {
      clue: 'Colour I turned the shard for you and first flavour',
      answer: 'GRAPE',
      row: 13,
      col: 0,
    },
    22: {
      clue: 'The game you started me doing regularly',
      answer: 'WORDLE',
      row: 14,
      col: 17,
    },
    23: {
      clue: 'How many sisters during our hike?',
      answer: 'SEVEN',
      row: 14,
      col: 20,
    },
    25: {
      clue: 'Winner of the sport that I never watched a full game of until with you',
      answer: 'ALCARAZ',
      row: 15,
      col: 2,
    },
    26: {
      clue: 'Where I was when you first messaged',
      answer: 'HASTINGS',
      row: 15,
      col: 14,
    },
    28: {
      clue: 'We have ___ at home!',
      answer: 'SOUVLAKI',
      row: 16,
      col: 8,
    },
    33: {
      clue: 'Building name of our first kiss',
      answer: 'HYLO',
      row: 20,
      col: 5,
    },
    35: {
      clue: 'Who\'s store in our first play?',
      answer: 'KIMS',
      row: 21,
      col: 18,
    },
    
  },
}

function CrosswordPage() {
 return(
  <Crossword data={data} />
 );
}

export default CrosswordPage;
