import React from 'react';
import './App.css'; 

function LandingPage() {
    return (
        <div className="container">
            <h1 className="header">YOGZZLE!</h1>
            <p className="paragraph">💾 This is what happens when you get a backend engineer to design a website for you. It looks straight out of 1992. 💾</p>
            <p className="paragraph">🤖 Yes I also used chatGPT to help me make this lol. 🤖</p>
            <h3 className="paragraph">🥰 Anyway, welcome to your personal anniversary website! 🥰</h3>
            <p className="paragraph">🤔 You may be wondering why the name yogzzle? 🤔</p>
            <p className="paragraph">🧩 Well, it's actually my brilliant way of combining your name with the word puzzle! 🧩</p>
            <p className="paragraph">🧐 I know you're already amazing at puzzles but if you get stuck, then just ask me, I should be sat opposite you smiling. 🧐</p>
            <p className="paragraph">🏁 To get started, your first task is to enter the right URL to get to the game! 🏁</p>
            <h3 className="paragraph">🏨 yogzzle.com/x where x is the second word of the name of this hotel! 🏨</h3>
        </div>
    );
}

export default LandingPage;